export enum PickUpDynamicRange {
    TODAY = 'Today',
    TOMORROW = 'Tomorrow',
    NEXTWEEK = 'Next week',
    NEXT2WEEK = 'Next 2 weeks',
    NEXT3WEEK = 'Next 3 weeks',
    NEXTMONTH = 'Next month',
    NEXT2MONTHS = 'Next 2 months',
    NEXT3MONTHS = 'Next 3 months',
    NEXT4MONTHS = 'Next 4 months',
    NEXT5MONTHS = 'Next 5 months',
    NEXT6MONTHS = 'Next 6 months',
    NEXT7MONTHS = 'Next 7 months',
    NEXT8MONTHS = 'Next 8 months',
    NEXT9MONTHS = 'Next 9 months',
    NEXT10MONTHS = 'Next 10 months',
    NEXT11MONTHS = 'Next 11 months',
    NEXTYEAR = 'Next year',
}

export const PickUpDynamicRangeList = [
    'Today', 'Tomorrow', 'Next week', 'Next 2 weeks', 'Next 3 weeks',
    'Next month', 'Next 2 months', 'Next 3 months', 'Next 4 months', 'Next 5 months', 'Next 6 months', 'Next 7 months', 'Next 8 months',
    'Next 9 months', 'Next 10 months', 'Next 11 months', 'Next year',
];

export enum PickUpConfigSelection {
    FIXED = '0',
    DYNAMIC = '1',
}
