
import * as moment from 'moment';
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select-bordered.vue';
import { defaultPercentageVal } from '@/modules/cars/modules/settings/config/alerts-notification.config';
import { inject } from '@/inversify';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';
import CustomRadioGroup from '@/modules/common/components/ui-kit/custom-radio-group.vue';
import { DatePicker, RadioGroup } from 'element-ui';
import { PickUpDynamicRangeList, PickUpDynamicRange, PickUpConfigSelection } from '@/modules/cars/constants/car-alert-settings.constant';
import CarsRateAlertService, { CarsRateAlertServiceS, notificationMessages } from '@/modules/cars/car.rate-alert.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CarsRateAlertConfigurationModel from '@/modules/cars/models/cars-rate-alert-configuration.model';
import { ICarRateAlertConfig } from '../interfaces/alert-interfaces';

@Component({
    components: {
        CustomSelect,
        ModalWrapper,
        CustomMultiSelect,
        CustomRadioGroup,
        RadioGroup,
        DatePicker,
    },
})

export default class CarRateAlertConfigsPopup extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @inject(CarsRateAlertServiceS) private carsRateAlertService!: CarsRateAlertService;
    @inject(NotifyServiceS) private notifyService!: NotifyService;

    currentPercentage = this.currentConfig ? this.currentConfig.ratio : this.percentageVal[0].value;
    currentPosValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.pos.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentLokValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.lors.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentDataSource: string = 'Brand';
    currentLocationValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.locationIds.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentCategoryValue: Record<string, any>[] = this.currentConfig ? this.currentConfig.carCategories.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentFuelTypeValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.fuelType.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    selectedDate: Date[] = this.currentConfig && this.currentConfig.pickupDates.fixed
        ? this.currentConfig.pickupDates.fixed : [new Date(), new Date()];
    currentDynamicRangeFrom = this.currentConfig && this.currentConfig.pickupDates.dynamic
        ? this.currentConfig.pickupDates.dynamic[0] : PickUpDynamicRange.TODAY;
    currentDynamicRangeTo = this.currentConfig && this.currentConfig.pickupDates.dynamic
        ? this.currentConfig.pickupDates.dynamic[1] : PickUpDynamicRange.TOMORROW;
    selectedPicker: string = this.currentConfig && this.currentConfig.pickupDates.dynamic
        ? PickUpConfigSelection.DYNAMIC : PickUpConfigSelection.FIXED;

    get percentageVal() {
        return defaultPercentageVal;
    }

    get currentLorValue() {
        return this.carsService.storeState.settings.lor;
    }

    get getPosList() {
        const { allowedLocationPerPos } = this.carsFiltersService;
        if (!allowedLocationPerPos) {
            return [];
        }
        const selectedLocations = this.currentLocationValues.map(location => location.value);
        const posList = Array.from(allowedLocationPerPos).filter(([key, value]) => selectedLocations.includes(key)).map(([key, value]) => value);
        const availablePos = _.intersection(...posList);
        return Array.from(availablePos).map(value => ({
            name: String(value),
            value: String(value),
        }));
    }

    get getLokList() {
        const lor = this.carsFiltersService.availableLors;

        if (!lor) {
            return [];
        }
        const data = lor.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getDataSourceList() {
        const dataSource = ['Brand'];

        const data = dataSource.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getLocationList() {
        const { locations } = this.carsFiltersService.settings;
        if (!locations) {
            return [];
        }
        const data = locations.map(value => ({
            name: String(value.locationName),
            value: String(value.locationId),
        }));
        return data;
    }

    get getCarCategoryList() {
        const categories = this.carsFiltersService.allCarClasses;
        if (!categories) {
            return [];
        }
        const data = categories.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get fuelTypesList() {
        const fuleTypes = CAR_FUEL_TYPE;
        if (!fuleTypes) {
            return [];
        }
        const data = fuleTypes.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getPickUpDynamicRangeList() {
        const pickUpDynamicRange = PickUpDynamicRangeList;
        if (!pickUpDynamicRange) {
            return [];
        }
        const data = pickUpDynamicRange.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get id(): string {
        return this.$route.params.id;
    }

    get currentConfig(): CarsRateAlertConfigurationModel | null {
        const { data } = this.carsRateAlertService;
        if (this.id && data) {
            return data.filter(config => config.id === this.id)[0];
        }
        return null;
    }

    async handleAddOrUpdateButton() {
        if (!this.currentLocationValues.length || !this.currentPosValues.length || !this.currentCategoryValue.length
            || !this.currentFuelTypeValues.length) {
            this.notifyService.error(notificationMessages.FAILED);
            return;
        }
        const config: ICarRateAlertConfig = {
            ratio: this.currentPercentage,
            location_ids: this.currentLocationValues.map(loc => loc.value),
            pos: this.currentPosValues.map(pos => pos.value),
            car_categories: this.currentCategoryValue.map(category => category.value),
            fuel_type: this.currentFuelTypeValues.map(type => type.value),
            data_source: [this.currentDataSource],
            lors: this.currentLokValues.map(lok => lok.value),
            is_active: true,
        };

        if (this.selectedPicker === PickUpConfigSelection.FIXED && this.selectedDate.length) {
            config.pickup_dates = {
                fixed: this.selectedDate.map(date => moment.utc(date).format('YYYY-MM-DD')),
            };
        } else {
            config.pickup_dates = {
                dynamic: [this.currentDynamicRangeFrom, this.currentDynamicRangeTo],
            };
        }
        if (this.id) {
            await this.carsRateAlertService.updateRateAlertConfiguration(config, this.id);
        } else {
            await this.carsRateAlertService.saveRateAlertConfiguration(config);
        }
        this.notifyService.success(this.successMessage);
        this.carsRateAlertService.resetLoadings();
        this.close();
    }

    close() {
        this.$router.go(-1);
    }

    get successMessage() {
        return this.id ? notificationMessages.UPDATE_SUCCESS : notificationMessages.ADD_SUCCESS;
    }

    get actionButtonText() {
        return this.id ? 'Update' : 'Add';
    }
}

